import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import HamMenuDrawer from './HamMenuDrawer';

const NavigationBar = () => {
    const isLoggedIn = () => {
        // Check if local storage has session_id and expiry_time
        const session_id = localStorage.getItem('Access-Code');
        const expiryTime = localStorage.getItem('expiry_time');
        if (session_id && expiryTime) {
            // Check if expiry_time is greater than current time
            const currentTime = new Date().getTime();
            if (currentTime < parseInt(expiryTime)) {
                return true;
            }
        }
        // Remove all session data from local storage
        localStorage.clear();
        sessionStorage.clear();
        return false;
    };

    const handleLogout = () => {
        // Remove all session data from local storage
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
    };

    const handleHome = () => {
        window.location.href = '/';
    };

    return (
        <AppBar position="fixed">
            <Toolbar>
                <HamMenuDrawer />
                <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit' }}>Central Logging System</Typography>
                <div style={{ flexGrow: 1 }} />
                <Button onClick={handleHome} color="inherit" sx={{marginRight: 4}}>Home</Button>
                {isLoggedIn() ? (
                    <>
                        <Button onClick={handleLogout} color="inherit">Logout</Button>
                    </>
                ) : (
                    <Button component={Link} to="/login" color="inherit">Login</Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavigationBar;
