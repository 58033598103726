import { Box, Typography } from '@mui/material';
import React from 'react';
import './HomePage.css';

const HomePage = () => {
    const headings = [
        'Welcome to Central Logging System',
        'This is a simple Central Logging System built using React and API Platform',
        'Please login to continue',
        'Click on the Dashboard link in the navigation bar to view the dashboard'
    ];

    const userLoggedIn = localStorage.getItem('Access-Code') && localStorage.getItem('expiry_time');

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
            <Typography variant="h2" gutterBottom>{headings[0]}</Typography>
            <Typography variant="h5" gutterBottom>
                {headings[1]}{' '}
            </Typography>
            <Typography variant='h5' mt={4}>
                {userLoggedIn ? headings[3] : headings[2]}
            </Typography>
        </Box>
    );
};

export default HomePage;
