import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import DvrIcon from '@mui/icons-material/Dvr';


const HamMenuDrawer = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const isLoggedIn = () => {
        // Check if local storage has session_id and expiry_time
        const session_id = localStorage.getItem('Access-Code');
        const expiryTime = localStorage.getItem('expiry_time');
        if (session_id && expiryTime) {
            // Check if expiry_time is greater than current time
            const currentTime = new Date().getTime();
            if (currentTime < parseInt(expiryTime)) {
                return true;
            }
        }
        // Remove all session data from local storage
        localStorage.clear();
        sessionStorage.clear();
        return false;
    };

    const eachMenu = (text, link, icon, index) => {
        const navOnClick = () => {
            window.location.href = link;
        }

        return (
            <ListItem key={index} disablePadding>
                <ListItemButton onClick={navOnClick}>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
            </ListItem>
        );
    }

    const afterLogin = () => {
        return (
            <>
                {/* {eachMenu('Dashboard', '/dashboard', <DashboardIcon />, 1)} */}
                {eachMenu('All Systems Logs', '/logs', <DvrIcon />, 1)}
            </>
        );
    }

    const beforeLogin = () => {
        return (
            <>
                {eachMenu('Login', '/login', <LoginIcon />, 1)}
            </>
        );
    }

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open menu"
                onClick={toggleDrawer(true)}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
                <MenuIcon />
            </IconButton>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
                    <List>
                        { eachMenu('Home', '/', <HomeIcon />, 0) }
                        { isLoggedIn() ? afterLogin() : beforeLogin() }
                    </List>
                    <Divider />
                    <List>
                        {/* { eachMenu('Contact', '/contact', <SupportAgentIcon />, 2) } */}
                        { eachMenu('About', '/about', <InfoIcon />, 3) }
                    </List>
                </Box>
            </Drawer>
        </>
    );
}

export default HamMenuDrawer;
