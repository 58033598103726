import { Button } from '@mui/base';
import React from 'react';


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Arial, sans-serif'
    },
    title: {
        fontSize: '3rem',
        marginBottom: '1rem',
    },
    message: {
        fontSize: '1.5rem',
        textAlign: 'center',
    },
};

const NotFound = () => {
    // Set 404 status code in the response
    React.useEffect(() => {
        document.title = '404 - Not Found';
        return () => {
            document.title = 'Central Logging System';
        };
    }, []);

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>404 - Not Found</h1>
            <p style={styles.message}>Sorry, the page you are looking for does not exist.</p>
            <Button variant="contained" color="primary" href="/"> Go to Home </Button>
        </div>
    );
};


export default NotFound;
