import React, { useState, useEffect } from 'react';
import { Typography, Container, Pagination } from '@mui/material';
import SearchForm from '../components/SearchForm';
import LogsTable from '../components/LogsTable';
import LogDialog from '../components/LogDialog';
import axios from 'axios';


function getTimeWithOffset(offset) {
    const date = new Date();
    const tzOffset = date.getTimezoneOffset() * 60000; // Local timezone offset in milliseconds
    const adjustedDate = new Date(date.getTime() - tzOffset + offset * 60000); // Adjust with the given offset in minutes
    return adjustedDate.toISOString().slice(0, 19);
}


function CentralLogs() {
    const [logs, setLogs] = useState([]);
    const [query, setQuery] = useState('');
    const [logLevel, setLogLevel] = useState('');
    const [serverName, setServerName] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [startTime, setStartTime] = useState(getTimeWithOffset(-10)); // Offset of 10 minutes
    const [endTime, setEndTime] = useState(getTimeWithOffset(0));       // Current time
    const [filters, setFilters] = useState([{ key: '', value: '' }]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalLogEntries, setTotalLogEntries] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null);

    const isLoggedIn = () => {
        // Check if local storage has session_id and expiry_time
        const session_id = localStorage.getItem('Access-Code');
        const expiryTime = localStorage.getItem('expiry_time');
        if (session_id && expiryTime) {
            // Check if expiry_time is greater than current time
            const currentTime = new Date().getTime();
            if (currentTime < parseInt(expiryTime)) {
                return true;
            }
        }
        // Remove all session data from local storage
        localStorage.clear();
        sessionStorage.clear();
        return false;
    };

    if (!localStorage.getItem('Access-Code')) {
        window.location.href = '/login';
    }

    useEffect(() => {
        searchLogs();
        if (!isLoggedIn()) {
            alert('Session expired. Please login again.');
            window.location.href = '/login';
        }
    }, [page]);

    const handleFilterChange = (index, event) => {
        const values = [...filters];
        values[index][event.target.name] = event.target.value;
        setFilters(values);
    };

    const addFilterField = () => {
        setFilters([...filters, { key: '', value: '' }]);
    };

    const searchLogs = async () => {
        let filterQuery = filters.reduce((acc, filter) => {
            if (filter.key && filter.value) {
                acc[filter.key] = filter.value;
            }
            return acc;
        }, {});

        let bodyData = {};
        if (query) {
            bodyData.query_text = query;
        }
        if (startTime) {
            bodyData.start_time = startTime;
        } else {
            alert('Start Time is required to search logs and note that Search is only possible for the 1 day data.');
            return;
        }
        if (endTime) {
            bodyData.end_time = endTime;
            // Validate if end time is greater than start time and lies in the same day
            if (new Date(endTime).getDate() !== new Date(startTime).getDate()) {
                alert('End Time should be in the same day as Start Time.');
                return;
            }
            if (new Date(endTime) < new Date(startTime)) {
                alert('End Time should be greater than Start Time.');
                return;
            }
        }
        if (logLevel) {
            filterQuery.log_level = logLevel;
        }
        if (serverName) {
            filterQuery.server_name = serverName;
        }
        if (serviceName) {
            filterQuery.service_name = serviceName;
        }
        if (Object.keys(filterQuery).length) {
            bodyData.filter_query = filterQuery;
        }

        try {
            const response = await axios.post(`https://central.logs.api.yukthi.net/search?deployment_type=development&page=${page}&page_size=20`, bodyData, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Access-Code': atob(localStorage.getItem('Access-Code')),
                },
            });
            const data = response.data;
            setPage(data.pagination.current_page);
            setLogs(data.results);
            setTotalPages(data.pagination.total_pages);
            setTotalLogEntries(data.pagination.total_entries);
        } catch (error) {
            setPage(1);
            console.error('Error fetching logs:', error);
        }
    };

    const handleClickOpen = (log) => {
        setSelectedLog(log);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedLog(null);
    };

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <SearchForm
                query={query}
                setQuery={setQuery}
                setLogLevel={setLogLevel}
                setServerName={setServerName}
                setStartTime={setStartTime}
                setServiceName={setServiceName}
                setFilters={setFilters}
                setEndTime={setEndTime}
                serviceName={serviceName}
                logLevel={logLevel}
                serverName={serverName}
                startTime={startTime}
                endTime={endTime}
                filters={filters}
                handleFilterChange={handleFilterChange}
                addFilterField={addFilterField}
                searchLogs={searchLogs}
            />
            <LogsTable logs={logs} handleClickOpen={handleClickOpen} />
            <Pagination
                count={totalPages}
                page={page}
                onChange={(event, value) => setPage(value)}
                color="primary"
                style={{ marginTop: '20px' }}
            />
            <Typography variant="caption" style={{ marginTop: '20px', marginBottom: '20px', fontSize: '1rem' }}>
                Total Log Entries Found: {totalLogEntries.toLocaleString('en-IN')}
            </Typography>
            <LogDialog open={open} handleClose={handleClose} selectedLog={selectedLog} />
        </Container>
    );
}

export default CentralLogs;
