import React from 'react';
import { 
    TextField,
    IconButton,
    Button,
    Container,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem } from '@mui/material';

import { Delete } from '@mui/icons-material';

const SearchForm = ({
    query, setQuery,
    logLevel, setLogLevel,
    serverName, setServerName,
    startTime, setStartTime,
    endTime, setEndTime,
    filters, handleFilterChange,
    serviceName, setFilters,
    setServiceName, addFilterField,
    searchLogs
}) => {    
    // TODO: Add EUID filter field
    // EUID normal search key value is : "metadata.euid" (this should be the key when sending the request)


    // Not so important but similar needs to be done in other projects so feel free to do it as a test task
    // TODO: Service Name should be a dropdown + custom input
    // TODO: Log Level should be a dropdown + custom input
    const logLevelOptions = [
        { value: '', label: 'None' },
        { value: 'DEBUG', label: 'Debug'},
        { value: 'INFO', label: 'Info'},
        { value: 'WARNING', label: 'Warning'},
        { value: 'ERROR', label: 'Error'},
        { value: 'CRITICAL', label: 'Critical'},
    ]
    // TODO: Server Name should be a dropdown + custom input

    return (
        <Container
            maxWidth="md"
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Query Text"
                        value={query}
                        fullWidth
                        onChange={(e) => setQuery(e.target.value)}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl 
                        margin="normal" 
                        fullWidth
                    >
                        <InputLabel>
                            Log Level
                        </InputLabel>
                        <Select
                            label="Log Level"
                            value={logLevel}
                            onChange={(e) => setLogLevel(e.target.value)}
                        >
                            {logLevelOptions.map((option) => (
                                <MenuItem 
                                    value={option.value} 
                                    key={option.value}
                                >
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Server Name"
                        value={serverName}
                        onChange={(e) => setServerName(e.target.value)}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Service Name"
                        value={serviceName}
                        onChange={(e) => setServiceName(e.target.value)}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="datetime-local"
                        label="Start Time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type="datetime-local"
                        label="End Time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>

                {/* Filters */}
                <Grid item xs={12}>
                    {filters.map((filter, index) => (
                        <Grid container spacing={2} alignItems="center" key={index} style={{ marginBottom: '10px' }}>
                            <Grid item xs={5}>
                                <TextField
                                    label="Filter Key"
                                    name="key"
                                    value={filter.key}
                                    onChange={(e) => handleFilterChange(index, e)}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="Filter Value"
                                    name="value"
                                    value={filter.value}
                                    onChange={(e) => handleFilterChange(index, e)}
                                    margin="normal"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <IconButton
                                    onClick={() => setFilters(filters.filter((_, i) => i !== index))}
                                    style={{ padding: '10px', backgroundColor: '#f50057', color: '#fff', borderRadius: '5px' }}
                                >
                                    <Delete style={{ fontSize: '20px' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>

                <Grid item xs={12}>
                    <Button
                        onClick={addFilterField}
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '10px', height: '50px', fontSize: '16px' }}
                    >
                        Add Filter
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        onClick={searchLogs}
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '10px', height: '50px', fontSize: '16px' }}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SearchForm;
