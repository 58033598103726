import React from 'react';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const useStyles = makeStyles({
    tableContainer: {
        marginTop: 20,
        boxShadow: '0 3px 6px rgba(0,0,0,0.1)',
        borderRadius: 10,
    },
    table: {
        minWidth: 650,
    },
    tableHead: {
        backgroundColor: '#dedede', // Light gray background for the header
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#fafafa', // Very light gray for odd rows
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#f0f0f0', // Slightly darker light gray for even rows
        },
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#e0e0e0', // Medium gray on hover
        },
    },
    tableCell: {
        color: '#555', // Medium gray for text
        transition: 'color 0.3s ease',
        '&:hover': {
            color: '#333', // Slightly darker gray on hover
        },
    },
    actionButton: {
        backgroundColor: '#888', // Medium gray for the button
        color: '#fff',
        transition: 'background-color 0.3s ease, transform 0.3s ease',
        '&:hover': {
            backgroundColor: '#666', // Darker gray on hover
            transform: 'scale(1.05)', // Subtle scaling on hover
        },
    },
});

const LogsTable = ({ logs, handleClickOpen }) => {
    const classes = useStyles();

    const formatDateTime = (dateTime) => {
        console.log(dateTime);
        const date = new Date(dateTime);
    
        if (isNaN(date)) {
            return 'Invalid Date'; // Handle invalid dates
        }
    
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    
        return `${day}-${month}-${year} ${time}`;
    };
    
    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell style={{ color: '#333', fontWeight: 'bold', fontSize: '1rem' }}>Date & Time</TableCell>
                        <TableCell style={{ color: '#333', fontWeight: 'bold', fontSize: '1rem' }}>Log Level</TableCell>
                        <TableCell style={{ color: '#333', fontWeight: 'bold', fontSize: '1rem' }}>Server Host Name</TableCell>
                        <TableCell style={{ color: '#333', fontWeight: 'bold', fontSize: '1rem' }}>Service Name</TableCell>
                        <TableCell style={{ color: '#333', fontWeight: 'bold', fontSize: '1rem' }}>Log Message</TableCell>
                        <TableCell style={{ color: '#333', fontWeight: 'bold', fontSize: '1rem' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {logs.map((log, index) => (
                        <TableRow key={index} className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>{formatDateTime(log.time_stamp)}</TableCell>
                            <TableCell className={classes.tableCell}>{log.log_level}</TableCell>
                            <TableCell className={classes.tableCell}>{log.server_name}</TableCell>
                            <TableCell className={classes.tableCell}>{log.service_name}</TableCell>
                            <TableCell className={classes.tableCell}>
                                {log.message.substring(0, 50)}
                                {log.message.length > 50 ? ' . . .' : ''}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                                <Button 
                                    className={classes.actionButton} 
                                    onClick={() => handleClickOpen(log)}
                                >
                                    Details
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default LogsTable;
