import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import JSONViewer from 'react-json-view';

const LogDialog = ({ open, handleClose, selectedLog }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Log Details</DialogTitle>
            <DialogContent>
                {selectedLog && (
                    <div>
                        <Typography variant="h6">Log Message</Typography>
                        <Typography>{selectedLog.message}</Typography>
                        <Typography variant="h6" style={{ marginTop: '10px' }}>Metadata</Typography>
                        <JSONViewer src={selectedLog.metadata} name={false} displayDataTypes={false} />
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default LogDialog;
